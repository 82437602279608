import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from 'pages/Home';
import Contact from 'pages/Contact';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contact" component={Contact} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
