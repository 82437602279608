import styled, { css } from 'styled-components';

import { ButtonBase } from '@material-ui/core';

type Header = {
  variant: 'light' | 'dark';
};

export const Container = styled.header<Header>`
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 10px 40px rgba(3, 48, 101, 0.1);

  .content {
    width: 100%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 11px 0;

    > img {
      pointer-events: none;
    }

    nav {
      padding: 15px;

      a {
        font-family: PTSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.primary['blue-500']};
        text-decoration: none;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    .translation-area {
      display: flex;
      align-items: center;
    }
  }

  ${({ variant, theme }) =>
    variant === 'dark' &&
    css`
      background: ${theme.colors.primary['blue-500']};
      box-shadow: 0px 10px 40px rgba(3, 48, 101, 0.1);

      .content nav a {
        color: ${theme.colors.background};
      }
    `}

  @media (max-width: 768px) {
    .content {
      display: none;
    }
  }
`;

export const TranslationButton = styled(ButtonBase)<Header>`
  &:not(:last-child) {
    margin-right: 24px;
  }

  && {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 8px 11px;
    box-shadow: 0px 0px 10px rgba(3, 48, 101, 0.2);
    border-radius: 40px;

    > img {
      height: 10px;
      margin-bottom: 4px;
    }

    > small {
      font-family: PTSansBold;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 100%;
      color: ${({ theme }) => theme.colors.text['gray-8']};
    }

    ${({ variant }) =>
      variant === 'dark' &&
      css`
        background: ${({ theme }) => theme.colors.background};
        box-shadow: 0px 0px 10px rgba(3, 48, 101, 0.2);
      `}
  }

  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const MobileContainer = styled.div`
  padding: 15px;

  display: none;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 30px;
  }

  .translation-area {
    /* position: absolute;
    right: 0;
    margin-right: 65px; */

    display: flex;
    /* flex-direction: column; */
    align-items: center;

    /* > button {
      &:not(:first-child) {
        display: none;
      }
    } */
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const MenuIcon = styled.div<Header>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .line {
    display: initial;
    background: ${({ variant }) => (variant === 'dark' ? '#fff' : '#000')};
    height: 2px;
    width: 12px;
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .line + .line {
    width: 14px;
    /* display: none; */
  }

  .line + .line + .line {
    display: initial;
    width: 16px;
  }
`;

export const NavigatorMobile = styled.nav`
  display: none;
  position: fixed;
  background: ${({ theme }) => theme.colors.background};

  height: 100%;
  width: 100%;
  z-index: 9999999;

  padding: 25px 0 0 0;
  flex-direction: column;
  align-items: center;

  > a {
    font-family: PTSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary['blue-300']};
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;
