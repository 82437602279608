/* eslint-disable react/jsx-props-no-spreading */
import React, { TextareaHTMLAttributes, forwardRef } from 'react';

import PropTypes from 'prop-types';

import { Container, InputContainer } from './styles';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, IProps>(
  ({ label, error, ...inputProps }, ref) => (
    <Container className="input">
      <p className="label">{label}</p>
      <InputContainer {...inputProps} ref={ref} />
      {error && <p className="error-message">{error}</p>}
    </Container>
  ),
);

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

TextArea.defaultProps = {
  error: undefined,
};

export default TextArea;
