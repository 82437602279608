import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    margin-bottom: 8px;

    font-family: OpenSansBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.text['gray-8']};
  }

  .error-message {
    color: red;
    margin-top: 3px;
    font-family: OpenSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 130%;
  }
`;

export const InputContainer = styled.textarea`
  width: 100%;
  height: 135px;

  padding: 10px 24px 0;
  resize: none;

  outline: none;
  border: none;
  background: #f1f3f5;
  border-radius: 5px;
  box-sizing: border-box;

  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.text['gray-8']};

  :focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.secondary['coral-500']};
  }
`;
