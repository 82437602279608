import styled, { keyframes } from 'styled-components';

import { Button } from '@material-ui/core';

const spinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled(Button)`
  && {
    background: ${({ theme }) => theme.colors.secondary['coral-500']};
    border-radius: 5px;
    transition: all 0.3s linear;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.secondary['coral-500']};
      box-sizing: border-box;

      .MuiButton-label {
        color: ${({ theme }) => theme.colors.secondary['coral-500']};
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .MuiButton-label {
      padding: 20px 85px;

      text-transform: initial;
      font-family: OpenSansBold;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.background};

      > svg {
        animation: ${spinnerAnimation} 1s linear infinite;
      }
    }
  }

  @media (max-width: 768px) {
    && {
      .MuiButton-label {
        padding: 10px 0;

        font-size: 14px;
        text-align: center;
      }
    }
  }
`;
