import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { FiX } from 'react-icons/fi';
import { Animated } from 'react-animated-css';

import GridContainer from 'helpers/GridContainer';

import logoLightImg from 'assets/images/logo-light.svg';
import logoDarkImg from 'assets/images/logo-dark.svg';
import spainImg from 'assets/images/span.png';
import usaImg from 'assets/images/usa.png';
import brazilImg from 'assets/images/brazil.png';

import {
  Container,
  TranslationButton,
  MobileContainer,
  MenuIcon,
  NavigatorMobile,
} from './styles';

type THeader = {
  variant?: 'light' | 'dark';
};

const Header: React.FC<THeader> = ({ variant = 'light' }: THeader) => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = React.useState(i18n.language);
  const [openedMenu, setOpenedMenu] = React.useState(false);

  const handleLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const handleMenu = () => {
    setOpenedMenu(previousState => !previousState);
  };

  return (
    <Container variant={variant}>
      <GridContainer>
        <div className="content">
          <Link to="/">
            {variant === 'light' && (
              <img src={logoLightImg} alt="Nauta Loger" />
            )}
            {variant === 'dark' && <img src={logoDarkImg} alt="Nauta Loger" />}
          </Link>
          <nav>
            <a href="#about">{t('header.aboutus')}</a>
            <a href="#services">{t('header.services')}</a>
            <a href="#solutions">{t('header.solutions')}</a>
            <a href="#contact">{t('header.contactus')}</a>
          </nav>
          <div className="translation-area">
            {language !== 'pt-BR' && (
              <TranslationButton
                variant={variant}
                onClick={() => handleLanguage('pt-BR')}
              >
                <img src={brazilImg} alt="Spain flag" />
                <small>PT</small>
              </TranslationButton>
            )}
            {language !== 'es-ES' && (
              <TranslationButton
                variant={variant}
                onClick={() => handleLanguage('es-ES')}
              >
                <img src={spainImg} alt="Spain flag" />
                <small>ESP</small>
              </TranslationButton>
            )}
            {language !== 'en-US' && (
              <TranslationButton
                variant={variant}
                onClick={() => handleLanguage('en-US')}
              >
                <img src={usaImg} alt="USA flag" />
                <small>EN</small>
              </TranslationButton>
            )}
          </div>
        </div>
        <MobileContainer>
          {variant === 'light' && (
            <img src={logoLightImg} className="logo" alt="Nauta Loger" />
          )}
          {variant === 'dark' && (
            <img src={logoDarkImg} className="logo" alt="Nauta Loger" />
          )}
          {!openedMenu && (
            <div className="translation-area">
              {language !== 'pt-BR' && (
                <TranslationButton
                  variant={variant}
                  onClick={() => handleLanguage('pt-BR')}
                >
                  <img src={brazilImg} alt="Spain flag" />
                  <small>PT</small>
                </TranslationButton>
              )}
              {language !== 'es-ES' && (
                <TranslationButton
                  variant={variant}
                  onClick={() => handleLanguage('es-ES')}
                >
                  <img src={spainImg} alt="Spain flag" />
                  <small>ESP</small>
                </TranslationButton>
              )}
              {language !== 'en-US' && (
                <TranslationButton
                  variant={variant}
                  onClick={() => handleLanguage('en-US')}
                >
                  <img src={usaImg} alt="USA flag" />
                  <small>EN</small>
                </TranslationButton>
              )}
            </div>
          )}
          <IconButton onClick={handleMenu}>
            <MenuIcon variant={variant}>
              {!openedMenu ? (
                <>
                  <div className="line" />
                  <div className="line" />
                  <div className="line" />
                </>
              ) : (
                <FiX size={24} color="#000" />
              )}
            </MenuIcon>
          </IconButton>
        </MobileContainer>
      </GridContainer>
      {openedMenu && (
        <Animated
          animationIn="bounceInDown"
          animationOut="bounceOutUp"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={openedMenu}
        >
          <NavigatorMobile>
            <a href="#about" onClick={handleMenu}>
              {t('header.aboutus')}
            </a>
            <a href="#services" onClick={handleMenu}>
              {t('header.services')}
            </a>
            <a href="#solutions-mobile" onClick={handleMenu}>
              {t('header.solutions')}
            </a>
            <a href="#contact" onClick={handleMenu}>
              {t('header.contactus')}
            </a>
          </NavigatorMobile>
        </Animated>
      )}
    </Container>
  );
};

Header.defaultProps = {
  variant: 'light',
};

export default Header;
