import { css } from 'styled-components';

// PTSans
import PTSansRegular from 'assets/fonts/PTSans/PTSans-Regular.ttf';
import PTSansBold from 'assets/fonts/PTSans/PTSans-Bold.ttf';

// Raleway
import RalewayThin from 'assets/fonts/Raleway/Raleway-Thin.ttf';
import RalewayLight from 'assets/fonts/Raleway/Raleway-Light.ttf';
import RalewayExtraLight from 'assets/fonts/Raleway/Raleway-ExtraLight.ttf';
import RalewayRegular from 'assets/fonts/Raleway/Raleway-Regular.ttf';
import RalewayMedium from 'assets/fonts/Raleway/Raleway-Medium.ttf';
import RalewaySemiBold from 'assets/fonts/Raleway/Raleway-SemiBold.ttf';
import RalewayBlack from 'assets/fonts/Raleway/Raleway-Black.ttf';
import RalewayBold from 'assets/fonts/Raleway/Raleway-Bold.ttf';
import RalewayExtraBold from 'assets/fonts/Raleway/Raleway-ExtraBold.ttf';

// OpenSans
import OpenSansLight from 'assets/fonts/OpenSans/OpenSans-Light.ttf';
import OpenSansRegular from 'assets/fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansSemiBold from 'assets/fonts/OpenSans/OpenSans-SemiBold.ttf';
import OpenSansBold from 'assets/fonts/OpenSans/OpenSans-Bold.ttf';
import OpenSansExtraBold from 'assets/fonts/OpenSans/OpenSans-ExtraBold.ttf';

export default css`
  // OpenSans
  @font-face {
    font-family: 'OpenSansLight';
    src: url(${OpenSansLight});
  }

  @font-face {
    font-family: 'OpenSansRegular';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'OpenSansSemiBold';
    src: url(${OpenSansSemiBold});
  }

  @font-face {
    font-family: 'OpenSansBold';
    src: url(${OpenSansBold});
  }

  @font-face {
    font-family: 'OpenSansExtraBold';
    src: url(${OpenSansExtraBold});
  }

  @font-face {
    font-family: 'RalewayThin';
    src: url(${RalewayThin});
  }

  @font-face {
    font-family: 'RalewayLight';
    src: url(${RalewayLight});
  }

  @font-face {
    font-family: 'RalewayExtraLight';
    src: url(${RalewayExtraLight});
  }

  @font-face {
    font-family: 'RalewayMedium';
    src: url(${RalewayMedium});
  }

  @font-face {
    font-family: 'RalewayRegular';
    src: url(${RalewayRegular});
  }

  @font-face {
    font-family: 'RalewaySemiBold';
    src: url(${RalewaySemiBold});
  }

  @font-face {
    font-family: 'RalewayBlack';
    src: url(${RalewayBlack});
  }

  @font-face {
    font-family: 'RalewayBold';
    src: url(${RalewayBold});
  }

  @font-face {
    font-family: 'RalewayExtraBold';
    src: url(${RalewayExtraBold});
  }

  @font-face {
    font-family: 'PTSansRegular';
    src: url(${PTSansRegular});
  }

  @font-face {
    font-family: 'PTSansBold';
    src: url(${PTSansBold});
  }
`;
