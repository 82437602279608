export const messages = {
  en: {
    translations: {
      contactPage: {
        validationFields: {
          requiredName: 'Please fill in your name.',
          requiredEmail: 'Please fill in a valid.',
          requiredPhone: 'Please fill in your phone.',
          validEmail: 'Please fill in a valid email.',
        },
        title: "Let's talk?",
        form: {
          title: 'We are waiting for you',
          subtitle: 'We will be glad with your contact ',
          name: 'Your name',
          email: 'E-mail',
          phone: 'Phone number',
          message: 'Text',
          button: 'Send',
        },
        contactUs: {
          title: 'Our contacts',
          services: {
            servicesTitle: 'Services',
            one: 'International freight',
            two: 'Customs brokerage',
            three: 'Project and customs advisory',
            four: 'Advisory on import and export of services.',
            five: 'Training',
            six: 'Foreing partnerships',
          },
        },
      },
      header: {
        aboutus: 'About us',
        services: 'Services',
        solutions: 'Solutions',
        contactus: 'Contact us',
      },
      main: {
        title: 'Nauta Loger making your import and export easier',
        subtitle:
          'We provide you the best international logistics solution with intelligence and efficiency',
      },
      about: {
        title:
          'We are a Brazilian company founded in 2016 in the city of Marilia, state of São Paulo',
        subtitle:
          'In the beginning of our business, CargoBrax was the original name of the company. After a process of brand repositioning and corporate division that took place in 2018, the new brand was born, Nauta Loger, a company focused on offering the best solutions for customers on customs brokerage, international air, ocean and truck freight, through a large network partners in Brazil and abroad.',
      },
      services: {
        title: 'Logistics Service',
        subtitle:
          'We pick up and deliver your export and import goods through air, ocean or truck transport.',
        tabs: {
          air: 'Air',
          ocean: 'Ocean',
          truck: 'Truck',
        },
        content: {
          air: {
            title: 'Your cargo flying in a security way.',
            one:
              'We pick up your cargo in any place in Brazil and in more than 120 countries.',
            two: 'We offer competive rates.',
            three: 'We have WCA - World Cargo Alliance agents as partners.',
            four: 'We handle normal, IMO or peresive cargo.',
            five:
              'We clean and ship goods to different kind of International Shows.',
          },
          ocean: {
            title: 'We are on FCL, LCL and RO-RO',
            one:
              'We pick up your cargo in any place in Brazil and in more than 120 countries in fill container or LCL.',
            two: 'We have partnership with the biggest NVOCCs of Brazil.',
            three:
              "Through our WCO's partners, we can offer more flexibility in providing services in different routes.",
            four:
              'Our team handles with high expertise (ODC) Over Dimensional Cargo or roll-on and roll-off cargo.',
            five:
              'We handle normal, IMO or peresive cargo, including refrigerated or frozen goods.',
          },
          truck: {
            title: '',
            one:
              'We offer transport by truck service for export and import from and to Argentine, Uruguay, Paraguay, Bolivia and Chile.',
            two:
              'Our team check all the requirements for transporting goods in the origin and destination of the goods.',
            three:
              'We handle special and standard cargoes offering transport insurance policy for refrigerated or frozen goods.',
            four: 'We give support on getting special transport licenses.',
            five:
              "We support international operations using customer's own vehicle.",
          },
        },
        collapse: {
          one: {
            title: 'Customs brokerage',
            description: 'Your operations are in safe hands with Nauta Loger.',
            items: {
              one: 'Analysis on tariff code',
              two:
                'We check and prepare export and import documents (invoice and packing list).',
              three: 'We issue diferent kind of certificate of origen.',
              four:
                "We register export and import declarations for Brazil's customs.",
              five:
                'We provide service of registration of companies following Brazil Agriculture Ministry requirement.',
              six:
                "We act as importers' and exporters' customs broker all over Brazil's customs.",
              seven:
                'We count with a system to manage operations with a direct transmission of data with Customs.',
            },
          },
          two: {
            title: 'Project and customs advisory',
            description: 'Get to know more about our expertise in consulting',
            items: {
              one: 'Brazil import duty reduction through ex-tarifario.',
              two:
                'Tax and duty consulting for import and export of products and services.',
              three:
                'Building of scenarios for imports comparing different origins and taxing over operations.',
              four: 'We help you to structure your foreign trade department.',
              five:
                'Full consulting in getting registration of products under the Agriculture Ministry of Brazil.',
            },
          },
          three: {
            title: 'Advisory on import and export of services',
            description: 'Get to know more about our expertise in consulting',
            items: {
              one: 'Service operation scenario analysis.',
              two: 'Analysis and determination of duty and tax.',
              three: 'Levantamento e aplicação de acordos de bitributação.',
              four: 'How to deal with tax haven operations.',
              five: 'Instructions over how to pay duties and taxes in Brazil. ',
              six: 'We issue tax payment form.',
              seven: 'Supporting on exchange rate contracts.',
              eight:
                'Comission over export and import and how to calculate duties.',
              nine: 'We tell you how to import and export services in Brazil.',
            },
          },
          four: {
            title: 'Training',
            description:
              'We offer you in-company or on-line the following options:',
            items: {
              one: 'Dealing with Incoterms.',
              two: 'Step by step on import operations.',
              three: 'Step by step on export operations.',
              four: "Brazil's taxiation over foreign trade operations.",
              five: 'How to import and export services in Brazil.',
              six: 'Creating an export culture in your business. ',
              seven:
                'Special operations in Brazil, drawback and temporary import and export.',
              eight:
                'Step by step on how to set up everything to participate in an international fair. ',
              nine:
                'Customer Service - preparing your team to  answer your customers. ',
            },
          },
          five: {
            title: 'Foreing partnerships',
            description:
              'Through our partners outside Brazil we can offer your company the following services:',
            items: {
              one: 'Express pick-up service.',
              two: 'Exclusive or consolidate pick-up.',
              three: 'Lashing, loading and unloading service.',
              four: 'Refrigerated and frozen cargo handling.',
              five: 'Customized Picking and Packing.',
              six:
                'Warehouse receipt with cargo details including pictures and temperature control.',
              seven:
                "Tax and fiscal analysis over operations outside Brazil under brazilian companies' responsibility. ",
            },
          },
        },
      },
      common: {
        provideyou: 'We provide you',
      },
      solutions: {
        title: 'Get to know our solutions outside Brazil.',
        description:
          'Add value to your business through our solutions on warehousing, picking and packing, repacking, cargo tracking and inventory management.',
      },
      contact: {
        title: 'Make your business stronger now',
        buttonText: 'Schedule a conversation',
      },
      footer: {
        aboutus: 'About us',
        services: 'Services',
        solutions: 'Solutions',
        contactus: 'Contact us',
      },
    },
  },
};
