/* eslint-disable prettier/prettier */
import React from 'react';

import { useTranslation } from 'react-i18next';

import GridContainer from 'helpers/GridContainer';

import logoBlackImg from 'assets/images/logo-dark.svg';
import facebookImg from 'assets/images/facebook.svg';
import instagramImg from 'assets/images/instagram.svg';
import whatsappImg from 'assets/images/whatsapp.svg';
import phoneImg from 'assets/images/phone.svg';
import mailImg from 'assets/images/mail.svg';

import { Container, DividerContainer } from './styles';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <GridContainer>
        <div className="wrapper">
          <div className="content">
            <img src={logoBlackImg} alt="Nauta Loger" />
            <nav className="nav-mobile">
              <a href="/">{t('footer.aboutus')}</a>
              <a href="/">{t('footer.services')}</a>
              <a href="/">{t('footer.solutions')}</a>
              <a href="/">{t('footer.contactus')}</a>
            </nav>
            <div className="info">
              <p className="location">Marília-SP</p>
              <p className="address">
                Rua, Bahia 165, Centro, Edíficio Nações Unidas, Sala 71, CEP
                17501-080.
              </p>
              <div className="social-icons">
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/nautalogeroficial/">
                  <img src={instagramImg} alt="Instagram" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/nautaloger/">
                  <img src={facebookImg} alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
          <nav className="nav-desk">
            <a href="#about">{t('footer.aboutus')}</a>
            <a href="#services">{t('footer.services')}</a>
            <a href="#solutions">{t('footer.solutions')}</a>
            <a href="#contact">{t('footer.contactus')}</a>
          </nav>
          <div className="contact-area">
            <a href="https://api.whatsapp.com/send?phone=5514996966140" target="_blank" rel="noreferrer" className="item">
              <img src={whatsappImg} alt="WhatsApp" />
              <p>+55 14 99696-6140</p>
            </a>
            <a href="tel:5514996966140" className="item">
              <img src={phoneImg} alt="Phone" />
              <p>+55 14 3434-0102</p>
            </a>
            <a href="mailto:contato@nautaloger.com" className="item">
              <img src={mailImg} alt="Mail" />
              <p>contato@nautaloger.com</p>
            </a>
          </div>
        </div>
        <DividerContainer>
          <div className="divisor" />
          <div className="company">
            <p>
              Nauta Loger Inteligência em Comercio Exterior, CNPJ:
              34.256.877/0001-60, todos os direitos reservados
              {' '}
            </p>
            <p>
              Feito por:
              <a href="https://aramacao.com.br/" rel="noreferrer" target="_blank">
                &nbsp;
                Ara Macao
              </a>
            </p>
          </div>
        </DividerContainer>
      </GridContainer>
    </Container>
  );
};

export default Footer;
