/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes, forwardRef } from 'react';

import PropTypes from 'prop-types';

import { Container, InputContainer } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
}

const Input = forwardRef<HTMLInputElement, IProps>(
  ({ label, error, ...inputProps }, ref) => (
    <Container className="input">
      <p className="label">{label}</p>
      <InputContainer {...inputProps} ref={ref} />
      {error && <p className="error-message">{error}</p>}
    </Container>
  ),
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

Input.defaultProps = {
  error: undefined,
};

export default Input;
