import styled from 'styled-components';

import backgroundImg from 'assets/images/contact-bg.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConctactContainer = styled.div`
  height: 285px;
  background: ${({ theme }) => theme.colors.primary['blue-500']};

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    margin-top: 65px;

    > h1 {
      max-width: 300px;

      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 64px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.background};
    }

    .form-area {
      margin-top: 30px;
      max-width: 705px;
      z-index: 1000;
    }
  }

  @media (max-width: 768px) {
    .content {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      margin: 40px 0;

      > h1 {
        font-size: 40px;
        text-align: center;
      }
    }
  }
`;

export const ContactInfoContainer = styled.div`
  width: 100%;
  height: 605px;
  background: url(${backgroundImg}) left top no-repeat;

  .content {
    margin-top: 80px;

    display: flex;
    flex-direction: column;
  }

  .contact-us {
    max-width: 200px;

    .title {
      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.primary['blue-500']};
    }

    .links {
      margin-top: 18px;

      display: flex;
      flex-direction: column;

      a {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        display: flex;
        align-items: center;

        text-decoration: none;
        font-family: OpenSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.text['gray-7']};

        > svg {
          margin-right: 8px;
        }
      }
    }
  }

  .divisor {
    margin: 50px 0;

    /* height: 1px; */
    width: 100px;
    border: 3px solid ${({ theme }) => theme.colors.secondary['coral-500']};
    border-radius: 3px;
  }

  .items-container {
    .title {
      font-family: RalewayBold;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      color: ${({ theme }) => theme.colors.primary['blue-500']};

      margin-bottom: 16px;
    }

    .wrapper {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      > p {
        margin-left: 8px;

        font-family: OpenSansRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.text['gray-7']};
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 650px;
    background: none;

    .content {
      margin-top: 50px;
    }

    .contact-us {
      max-width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .links {
        align-items: center;
      }
    }

    .divisor {
      align-self: center;
    }

    .contact-us .title {
      text-align: center;
    }

    .items-container .title {
      align-self: center;
      text-align: center;
    }
  }
`;

export const Form = styled.form`
  background: #f8f9fa;
  box-shadow: 0px 30px 48px -8px rgba(102, 126, 154, 0.25);
  border-radius: 5px;
  padding: 30px 50px;

  display: flex;
  flex-direction: column;

  width: 540px;

  .title {
    font-family: RalewayBold;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.primary['blue-500']};
  }

  .subtitle {
    margin-top: 8px;

    font-family: OpenSansRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.text['gray-8']};
  }

  .input-area {
    margin-top: 14px;
    width: 100%;

    .input:not(:last-child) {
      margin-bottom: 16px;
    }

    > button {
      margin-top: 30px;
      width: 100%;
    }

    > button .MuiButton-label {
      padding: 7px 0;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 30px 24px;

    .input-area {
      > button {
        padding: 0 30px;
      }
    }
  }
`;
