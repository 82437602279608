import { createGlobalStyle } from 'styled-components';

import fontFaces from './fonts';

export default createGlobalStyle`
  ${fontFaces}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 10px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #dad7d7;
      border-radius: 10px;
    }
  }
  html {
    font-size: 10px;
  }
  html, body {
    min-height: 100%;
  }
  body {
    height: 100vh;
    width: 100%;
    display: block;
    font-weight: 400;
    font-size: 1.5rem;
    background-color: #fff;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: RalewayRegular, sans-serif;
  }
`;
