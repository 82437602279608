export default {
  title: 'light',

  colors: {
    primary: {
      'blue-600': '#002045',
      'blue-500': '#002856',
      'blue-400': '#335378',
      'blue-300': '#667E9A',
    },
    secondary: {
      'coral-900': '#2E100C',
      'coral-800': '#5C2018',
      'coral-700': '#8B2F25',
      'coral-600': '#B93F31',
      'coral-500': '#E74F3D',
      'coral-400': '#EC7264',
      'coral-300': '#F1958B',
      'coral-200': '#F5B9B1',
      'coral-100': '#FADCD8',
    },
    text: {
      'gray-8': '#343A40',
      'gray-7': '#495057',
      'gray-6': '#868E96',
      'gray-5': '#ADB5BD',
    },

    background: '#ffffff',
  },
};
