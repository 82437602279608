/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';

import DefaultLayout from 'layouts/DefaultLayout';
import GridContainer from 'helpers/GridContainer';
import Button from 'components/Button';

import checkImg from 'assets/images/check.svg';
import storageImg from 'assets/images/storage.png';

import {
  Container,
  TitleSection,
  TitleContainer,
  AboutContainer,
  LogisticServiceSection,
  LogisticContainer,
  LogisticTabsContainer,
  Tab,
  Tabs,
  TabContentContainer,
  FooterCardSection,
  FooterCard,
  PeopleReportsContainer,
  PeopleCard,
  SolutionsSection,
  SolutionsCard,
  CollapseLogisticSection,
  CollapseCard,
  CollapseContentContainer,
  PeopleReportsMobileContainer,
  CollapseLogisticSectionMobile,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SolutionsSectionMobile,
} from './styles';

import { logistics, peoplesReports, logisticCollapse } from './data';
import { logisticsContainer, logisticItem } from './animations';

function a11yProps(index: unknown) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [logisticCollapseValue, setLogisticCollapseValue] = useState(0);
  const [expandedCollapse, setExpandedCollapse] = useState(-1);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const isCollapseActive = (logisticValue: number): boolean =>
    logisticCollapseValue === logisticValue;

  const handleActiveCollapse = (logisticValue: number) => {
    setLogisticCollapseValue(logisticValue);
  };

  const handleMobileCollapse = (collapse: number): void => {
    setExpandedCollapse(lastValue => (lastValue === collapse ? -1 : collapse));
  };

  const switchToContactPage = () => {
    history.push('/contact');
  };

  return (
    <DefaultLayout>
      <Container>
        <TitleSection>
          <GridContainer>
            <TitleContainer language={i18n.language}>
              <div className="content">
                <h1>{t('main.title')}</h1>
                <h4>{t('main.subtitle')}</h4>
              </div>
            </TitleContainer>
          </GridContainer>
        </TitleSection>
        <GridContainer>
          <AboutContainer id="about">
            <div className="content">
              <h2>{t('about.title')}</h2>
              <p>{t('about.subtitle')}</p>
            </div>
          </AboutContainer>
        </GridContainer>

        {/* LogisticContainer */}
        <LogisticServiceSection id="services">
          <GridContainer>
            <LogisticContainer>
              <img src={logistics[value].cover} alt="An airplaine" />
              <div className="content">
                <h1>{t('services.title')}</h1>
                <p>{t('services.subtitle')}</p>
                <LogisticTabsContainer>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                  >
                    <Tab label={t('services.tabs.air')} {...a11yProps(0)} />
                    <Tab label={t('services.tabs.ocean')} {...a11yProps(1)} />
                    <Tab label={t('services.tabs.truck')} {...a11yProps(2)} />
                  </Tabs>
                  <TabContentContainer>
                    <p className={`title ${value === 2 && 'minor'}`}>
                      {t(logistics[value].title)}
                    </p>
                    <motion.div
                      className="container"
                      variants={logisticsContainer}
                    >
                      {logistics[value].items.map(description => (
                        <motion.div
                          className="item"
                          variants={logisticItem}
                          key={description}
                        >
                          <img src={checkImg} alt="Check" />
                          <p>{t(description)}</p>
                        </motion.div>
                      ))}
                    </motion.div>
                  </TabContentContainer>
                </LogisticTabsContainer>
              </div>
            </LogisticContainer>
          </GridContainer>
        </LogisticServiceSection>

        {/* Logistic Explanation */}
        <CollapseLogisticSection>
          <GridContainer>
            <div className="wrapper">
              <div className="collapse-container">
                {logisticCollapse.map((item, index) => (
                  <CollapseCard
                    active={isCollapseActive(index)}
                    onClick={() => handleActiveCollapse(index)}
                  >
                    <div className="title-container">
                      <img src={item.icon} alt="Icon" className="icon" />
                      <div className="container">
                        <p className="title">{t(item.title)}</p>
                        <p className="description">{t(item.description)}</p>
                      </div>
                    </div>
                  </CollapseCard>
                ))}
              </div>
              <CollapseContentContainer>
                <p className="title">
                  {t(logisticCollapse[logisticCollapseValue].title)}
                </p>
                <p className="about">{t('common.provideyou')}</p>
                <div className="item-container">
                  {logisticCollapse[logisticCollapseValue].items.map(item => (
                    <div className="item" key={item}>
                      <img src={checkImg} alt="Check" />
                      <p>{t(item)}</p>
                    </div>
                  ))}
                </div>
              </CollapseContentContainer>
            </div>
          </GridContainer>
        </CollapseLogisticSection>

        {/* Logistic Explanation Mobile */}
        <CollapseLogisticSectionMobile>
          <GridContainer>
            {logisticCollapse.map((item, index) => (
              <Accordion
                expanded={expandedCollapse === index}
                onChange={() => handleMobileCollapse(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="title-container">
                    <img src={item.icon} alt="Icon" className="icon" />
                    <p>{t(item.title)}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="description">{t(item.description)}</p>
                  <CollapseContentContainer>
                    <p className="about">{t('common.provideyou')}</p>
                    <div className="item-container">
                      {item.items.map(content => (
                        <div className="item" key={content}>
                          <img src={checkImg} alt="Check" />
                          <p>{t(content)}</p>
                        </div>
                      ))}
                    </div>
                  </CollapseContentContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </GridContainer>
        </CollapseLogisticSectionMobile>

        {/* Storage */}
        <SolutionsSection id="solutions">
          <GridContainer>
            <div className="content">
              <SolutionsCard variants={logisticItem}>
                <p className="title">{t('solutions.title')}</p>
                <p className="description">{t('solutions.description')}</p>
              </SolutionsCard>
              <img src={storageImg} alt="Storage" />
            </div>
          </GridContainer>
        </SolutionsSection>
        <SolutionsSectionMobile id="solutions-mobile">
          <div className="content">
            <SolutionsCard variants={logisticItem}>
              <p className="title">{t('solutions.title')}</p>
              <p className="description">{t('solutions.description')}</p>
            </SolutionsCard>
            <img src={storageImg} alt="Storage" />
          </div>
        </SolutionsSectionMobile>

        {/* People are talking */}
        <PeopleReportsContainer>
          <GridContainer>
            <div className="wrapper">
              <p className="title">People are talking 💬</p>
              <div className="content">
                {peoplesReports.map(person => (
                  <PeopleCard key={person.name}>
                    <p className="report">{person.report}</p>
                    <div className="info">
                      <Avatar src={person.avatar} />
                      <div>
                        <p className="name">{person.name}</p>
                        <p className="position">{person.position}</p>
                        <p className="company">{person.company}</p>
                      </div>
                    </div>
                  </PeopleCard>
                ))}
              </div>
            </div>
          </GridContainer>
        </PeopleReportsContainer>

        {/* People are talking mobile */}
        <PeopleReportsMobileContainer>
          <p className="title">People are talking 💬</p>
          <GridContainer>
            <div className="content-container">
              <Swiper pagination>
                {peoplesReports.map(person => (
                  <SwiperSlide key={person.avatar}>
                    <PeopleCard>
                      <p className="report">{person.report}</p>
                      <div className="info">
                        <Avatar src={person.avatar} />
                        <div>
                          <p className="name">{person.name}</p>
                          <p className="position">{person.position}</p>
                          <p className="company">{person.company}</p>
                        </div>
                      </div>
                    </PeopleCard>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </GridContainer>
        </PeopleReportsMobileContainer>

        {/* Footer card */}
        <FooterCardSection id="contact">
          <GridContainer>
            <FooterCard>
              <p>{t('contact.title')}</p>
              <Button onClick={switchToContactPage}>
                {t('contact.buttonText')}
              </Button>
            </FooterCard>
          </GridContainer>
        </FooterCardSection>
      </Container>
    </DefaultLayout>
  );
};

export default Home;
