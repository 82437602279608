/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import { FaSpinner } from 'react-icons/fa';

import { Container } from './styles';

interface Props extends ButtonProps {
  children: React.ReactNode;
  fetching?: boolean;
}

const Button: React.FC<Props> = ({ children, fetching, ...rest }: Props) => {
  return (
    <Container {...rest} disabled={fetching}>
      {!fetching ? children : <FaSpinner size={20} color="#fff" />}
    </Container>
  );
};

Button.defaultProps = {
  fetching: false,
};

export default Button;
