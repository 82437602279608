import React, { useEffect } from 'react';

import { useTheme } from 'styled-components';
import { Whatsapp } from '@styled-icons/boxicons-logos';
import { MdMail, MdCheckCircle } from 'react-icons/md';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';

import api from 'services/api';
import { phoneMask } from 'helpers/formats';

import DefaultLayout from 'layouts/DefaultLayout';
import GridContainer from 'helpers/GridContainer';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';

import {
  Container,
  ConctactContainer,
  ContactInfoContainer,
  Form,
} from './styles';

import { services } from './data';

interface IForm {
  name: string;
  email: string;
  phone: string;
  message?: string;
}

const fields = yup.object().shape({
  name: yup.string().required('contactPage.validationFields.requiredName'),
  email: yup
    .string()
    .email('contactPage.validationFields.validEmail')
    .required('contactPage.validationFields.requiredEmail'),
  phone: yup.string().required('contactPage.validationFields.requiredPhone'),
  message: yup.string(),
});

export interface State {
  open: boolean;
  severety?: Color;
  message?: string;
}

const Contact: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
  });
  const [fetching, setFetching] = React.useState(false);
  const { open } = state;

  const { handleSubmit, register, errors } = useForm<IForm>({
    resolver: yupResolver(fields),
  });

  const handleOpenSnack = (newState: State) => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState(previousState => {
      return { ...previousState, open: false };
    });
  };

  const onSubmit = async ({ name, email, phone, message }: IForm) => {
    // eslint-disable-next-line no-console
    try {
      setFetching(true);

      const response = await api.get(
        `/mail.php?name=${name}&email=${email}&phone=${phone}&message=${message}`,
      );

      if (response.data.ok) {
        handleOpenSnack({
          open: true,
          severety: 'success',
          message: 'Sucesso, agora é só aguardar o nosso contato.',
        });
      } else {
        handleOpenSnack({
          open: true,
          severety: 'error',
          message: 'Ocorreu um erro ao enviar o formulário, tente novamente.',
        });
      }
    } catch {
      handleOpenSnack({
        open: true,
        severety: 'error',
        message: 'Ocorreu um erro ao enviar o formulário, tente novamente.',
      });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout variant="dark">
      <Container>
        <ConctactContainer>
          <GridContainer>
            <div className="content">
              <h1>{t('contactPage.title')}</h1>
              <div className="form-area">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <p className="title">{t('contactPage.form.title')}</p>
                  <p className="subtitle">{t('contactPage.form.subtitle')}</p>
                  <div className="input-area">
                    <Input
                      label={t('contactPage.form.name')}
                      ref={register}
                      name="name"
                      error={t(errors.name?.message ?? '')}
                    />
                    <Input
                      label={t('contactPage.form.email')}
                      ref={register}
                      name="email"
                      error={t(errors.email?.message ?? '')}
                    />
                    <Input
                      label={t('contactPage.form.phone')}
                      onChange={event => {
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = phoneMask(event.target.value);
                      }}
                      ref={register}
                      name="phone"
                      error={t(errors.phone?.message ?? '')}
                    />
                    <TextArea
                      label={t('contactPage.form.message')}
                      ref={register}
                      name="message"
                      error={t(errors.message?.message ?? '')}
                    />
                    <Button type="submit" fetching={fetching}>
                      {t('contactPage.form.button')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </GridContainer>
        </ConctactContainer>
        <ContactInfoContainer>
          <GridContainer>
            <div className="content">
              <div className="contact-us">
                <p className="title">{t('contactPage.contactUs.title')}</p>
                <div className="links">
                  <a href="/#">
                    <Whatsapp
                      size={24}
                      color={theme.colors.secondary['coral-500']}
                    />
                    +55 14 99696-6140
                  </a>
                  <a href="/#">
                    <MdMail
                      size={24}
                      color={theme.colors.secondary['coral-500']}
                    />
                    contato@nautaloger.com
                  </a>
                </div>
              </div>
              <div className="divisor" />
              <div className="items-container">
                <p className="title">
                  {t('contactPage.contactUs.services.servicesTitle')}
                </p>
                {services.map(item => (
                  <div className="wrapper" key={item}>
                    <MdCheckCircle
                      size={24}
                      color={theme.colors.secondary['coral-500']}
                    />
                    <p>{t(item)}</p>
                  </div>
                ))}
              </div>
            </div>
          </GridContainer>
        </ContactInfoContainer>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={state.severety}>{state.message}</Alert>
      </Snackbar>
    </DefaultLayout>
  );
};

export default Contact;
