export const messages = {
  pt: {
    translations: {
      contactPage: {
        validationFields: {
          requiredName: 'Por favor, preencha seu nome.',
          requiredEmail: 'Por favor, preencha um e-mail.',
          requiredPhone: 'Por favor, preencha seu telefone.',
          validEmail: 'Por favor, preencha um e-mail válido.',
        },
        title: 'Vamos conversar?',
        form: {
          title: 'Estamos te esperando',
          subtitle: 'Ficaremos felizes com seu contato.',
          name: 'Seu nome*',
          email: 'Seu melhor e-mail*',
          phone: 'Seu melhor telefone*',
          message: 'Mensagem',
          button: 'Enviar Contato',
        },
        contactUs: {
          title: 'Nossos contatos',
          services: {
            servicesTitle: 'Serviços',
            one: 'Agenciamento de cargas',
            two: 'Desembaraço aduaneiro',
            three: 'Assessoria aduaneira e projetos',
            four: 'Assessoria na Impo. e Expo. de serviços',
            five: 'Treinamentos personalizados',
            six: 'Parcerias no exterior',
          },
        },
      },
      header: {
        aboutus: 'Sobre nós',
        services: 'Serviços',
        solutions: 'Soluções',
        contactus: 'Contato',
      },
      main: {
        title: 'Importação e exportação é com a Nauta Loger',
        subtitle:
          'Entregamos a melhor solução em logística internacional com inteligência e eficiência.',
      },
      about: {
        title:
          'Somos uma empresa brasileira que nasceu em 2016 na cidade de Marília no interior de São Paulo',
        subtitle:
          'A empresa foi fundada com o nome Cargobrax e após um processo de reposicionamento e reformulação societária ocorrida em 2018, foi criada em 2019 a nova marca, Nauta Loger, uma empresa com foco nas operações de desembaraço aduaneiro de mercadorias, agenciamento de cargas aéreas, marítimas e rodoviárias, e soluções em logística internacional através de uma ampla rede de parceiros no Brasil e no exterior.',
      },
      services: {
        title: 'Agenciamento de Cargas',
        subtitle:
          'Coletamos ou entregamos cargas de exportação e/ou importação através do transporte aéreo, marítimo e rodoviário.',
        tabs: {
          air: 'Aéreo',
          ocean: 'Marítimo',
          truck: 'Rodoviário',
        },
        content: {
          air: {
            title: 'Sua carga voando de forma mais segura.',
            one:
              'Coletamos sua carga em qualquer ponto do Brasil e em mais de 120 países no exterior.',
            two: 'Possuímos tarifas competitivas nas mais diferentes rotas.',
            three:
              'Somos parceiros de agentes internacionais ligados a World Cargo Alliance (WCA).',
            four:
              'Atuamos com o transporte de cargas normais, IMO ou perecíveis.',
            five:
              'Atuamos com envio de cargas para Feiras e Eventos Internacionais.',
          },
          ocean: {
            title: 'Atuação em FCL, LCL e RO-RO.',
            one:
              'Coletamos sua carga em qualquer ponto do Brasil e em mais de 120 países no exterior em full Container ou LCL.',
            two:
              'Somos parceiros dos maiores consolidadores de cargas do Brasil;',
            three:
              'Nossa parceria com os agentes da World Cargo Alliance (WCA) nos dá maior flexibilidade no atendimento de diferentes rotas.',
            four:
              'As cargas com dimensões especiais ou roll-on roll-off também são atendidas de forma profissional pelo nosso time.',
            five:
              'Atuamos com o transporte de cargas normais, IMO ou perecíveis, incluindo carga congelada ou refrigerada.',
          },
          truck: {
            title:
              'As exportações e importações rodoviárias também podem ser atendidas por este importante modal.',
            one:
              'Atendemos com nossos parceiros no transporte de cargas de exportação e de importação na Argentina, Uruguai, Paraguai, Bolívia e Chile.',
            two:
              'Analisamos todos os aspectos legais de trânsito dentro do país de origem ou destino das cargas com foco na busca do melhor veículo a ser oferecido.',
            three:
              'Trabalhamos as cargas que exigem um transporte com seguro e cobertura especial, cargas refrigeradas ou congeladas, além das cargas normais;',
            four: 'Apoiamos a emissão de licenças especiais de trânsito.',
            five:
              'Atuamos na assessoria de trânsito de carga internacional com veículo próprio.',
          },
        },
        collapse: {
          one: {
            title: 'Desembaraço aduaneiro',
            description: 'Com a Nauta Loger suas operações estão seguras.',
            items: {
              one: 'Analisamos classificação fiscal de mercadoria.',
              two: 'Preparamos e analisamos fatura comercial e packing List.',
              three:
                'Preparamos e emitimos junto aos órgãos anuentes, todos os diferentes modelos de certificado de origem.',
              four:
                'Registramos declaração de importação, declaração única de exportação e licenças de importação, além de conduzirmos processos com exigência de LPCO.',
              five:
                'Atuamos na elaboração e condução de registros de cadastramento de estabelecimento produtos, importador ou exportador junto ao MAPA.',
              six:
                'Atuamos diretamente junto a receita federal nas alfândegas de todo o Brasil como representantes legais de importadores e exportadores.',
              seven:
                'Contamos com um sistema de gestão de registros e operações de importação e exportação capaz de vincular os registros de nossa base de informações diretamente com os sistemas da receita federal.',
            },
          },
          two: {
            title: 'Assessoria aduaneira e projetos',
            description:
              'Conheça a diversidade de nossa atuação no assessoramento aos nossos clientes.',
            items: {
              one: 'Análise, solicitação, acompanhamento em ex-tarifário.',
              two:
                'Assessoria tributária na importação e na exportação de produtos e serviços.',
              three:
                'Construção de cenários de importação comparando origens distintas e aplicação de tributação e custos sobre cada operação.',
              four: 'Estruturação de departamento de comércio exterior.',
              five:
                'Assessoria para cadastros de produto e estabelecimento junto ao MAPA.',
            },
          },
          three: {
            title: 'Assessoria na Impo. e Expo. de Serviços',
            description:
              'Conheça a diversidade de nossa atuação no assessoramento aos nossos clientes.',
            items: {
              one: 'Enquadramento e determinação de operações de serviços.',
              two: 'Análise e determinação tributária.',
              three: 'Levantamento e aplicação de acordos de bitributação.',
              four: 'Aplicação tributária em operações com paraísos fiscais.',
              five:
                'Instrução sobre como oferecer a correta tributação ao fisco federal, estadual e municipal.',
              six: 'Emissão de guias de recolhimento tributário.',
              seven: 'Orientações sobre fechamento de câmbio.',
              eight:
                'Pagamento de comissões e aplicação em declarações de exportação.',
              nine: 'Como importar ou exportar serviços.',
            },
          },
          four: {
            title: 'Treinamentos personalizados',
            description:
              'In-company ou on-line, oferecemos os seguintes treinamentos:',
            items: {
              one: 'Incoterms aplicado.',
              two: 'Sistemática de importação.',
              three: 'Sistemática de exportação.',
              four: 'Tributação no comércio exterior.',
              five: 'Como importar ou exportar serviços.',
              six: 'Criando uma cultura de exportação.',
              seven:
                'Operações especiais, drawback, exportação e importação temporária.',
              eight:
                'Passo a passo para a preparação para uma Feira Internacional.',
              nine:
                'Atendimento ao cliente, como preparar seu time para atender bem o seu maior patrimônio.',
            },
          },
          five: {
            title: 'Parcerias no exterior',
            description:
              'Através de nossas parcerias no Exterior, nós podemos oferecer a nossos clientes os seguintes serviços:    ',
            items: {
              one: 'Serviço de coleta expressa.',
              two: 'Pick-up exclusivo ou consolidado.',
              three: 'Serviço de Lashing, ova e desova de carga.',
              four: 'Controle de estoque de carga e movimentação.',
              five: 'Armazenagem de cargas refrigeradas ou congeladas.',
              six:
                'Picking and Packing de acordo com a necessidade do cliente.',
              seven:
                'Análise fiscal e tributária no exterior para operações que envolvam incoterms de responsabilidade da empresa brasileira.',
            },
          },
        },
      },
      common: {
        provideyou: 'O que fazemos:',
      },
      solutions: {
        title: 'Conheça nossas soluções no exterior.',
        description:
          'Crie um diferencial para seu negócio através das soluções de armazenagem picking and pack, reembalagem, rastreamento e gestão de estoque.',
      },
      contact: {
        title: 'Potencialize seu negócio hoje mesmo',
        buttonText: 'Agendar uma conversa',
      },
      footer: {
        aboutus: 'Sobre nós',
        services: 'Serviços',
        solutions: 'Soluções',
        contactus: 'Contato',
      },
    },
  },
};
