import React from 'react';

import { ThemeProvider } from 'styled-components';

import Router from './routes';

import GlobalTheme from './styles/GlobalTheme';
import LightTheme from './styles/themes/light';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={LightTheme}>
      <Router />
      <GlobalTheme />
    </ThemeProvider>
  );
};

export default App;
