export const phoneMask = (phoneNumber: string): string => {
  try {
    const phone = phoneNumber
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1');

    return phone;
  } catch {
    return phoneNumber;
  }
};
